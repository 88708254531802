<template>
<div>
        <div class="text-center mt-5 " v-if="cargando">
            <div class="spinner-border text-primary spinner-border-sm" role="status">
                <span class="sr-only">Loading...</span>
            </div>
            <br>
            Cargando ...
        </div>

        <div v-if="!cargando" class="row">
            <div class="col-md-12">
                    
                    <span class="h4 titulosg">Personal </span> <br>
                    Registros Encontrados <span style="padding: 2px 10px 2px 10px;" class="ml-2 rounded-pill bg-primary text-white"><strong>{{paginacion.total}}</strong></span> 

                    <div class="p-4 bg-white mt-2 mb-2">
                        <form @submit.prevent="buscarpersona()">
                            <input autocomplete="off" placeholder="Buscar" type="text" class="form-control mb-2" v-model="criteriobusqueda" id="criteriobusqueda" required aria-describedby="button-addon2">
                            <button class="btn btn-primary" type="submit"> <i class="fas fa-search"></i> Buscar </button>
                            <button class="btn btn-secondary" type="button" v-on:click="listarpersonal(); criteriobusqueda=''"> <i class="fas fa-eraser"></i> Borrar </button>
                        </form>

                        <div class="d-flex  border-top pt-3 mt-2">
                            <a href="javascript:;" @click="listarpersonal()" class="me-3" ><i class="fas fa-building "></i> Todo</a>
                            <div class="custom-control custom-checkbox me-3"  v-for="sede in sedesautorizadas" :key="sede.idempresasede">
                                <input type="checkbox" class="custom-control-input me-1" :id="sede.idempresasede+'c'" v-model="filtrosedes" :value="sede.idempresasede">
                                <label class="custom-control-label" :for="sede.idempresasede+'c'">{{ sede.nombreempresasede }}</label>
                            </div>    
                            <button @click="filtrarPersonal">Filtrar</button>
                        </div>
                               
                    </div>

                    <div class="bg-white p-4">

                        <span class="bg-warning p-2 ml-3 text-white rounded-pill" v-if="haybusqueda"> Busqueda: {{criteriobusqueda}} </span>

                        <div class="table-responsive">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th style="width: 10%">Identificación</th>
                                    <th>Nombre</th>
                                    <th>Cargo</th>
                                    <th>Inicio</th>
                                    <th>Fin</th>
                                    <th style="width: 5%" class="text-center">Estado</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="persona in personas" :key="persona.idpersonas" >
                                    <td class="text-left">
                                        
                                        <router-link class="btn btn-light btn-sm" title="Ver"    :to="{ name: 'Infopersonal', params: { id: persona.idpersonas }}">
                                            <i class="fas fa-eye"></i> 
                                        </router-link>
                                        <router-link class="btn btn-light btn-sm" title="Editar" :to="{ name: 'Editarpersona', params: { id: persona.idpersonas }}">
                                            <i class="fas fa-pencil-alt"></i>
                                        </router-link>

                                    </td>
                                    <td style="width: 10%" class="text-end text-black-50">  {{persona.identificacion}}  </td>
                                    <td> <span class="text-capitalize"> 
                                        {{persona.nombres}} {{persona.apellidos}}</span>
                                    </td>
                                    <td> {{persona.cargo}} </td>
                                    <td> {{persona.fechacontrato}} </td>
                                    <td> {{persona.finalizacontrato}} </td>
                                    <td class="text-center">
                                        <span class="badge bg-success float-end badge-pill" v-if="persona.estado==1"> Activo </span>
                                        <span class="badge bg-danger float-end badge-pill" v-if="persona.estado==0"> Inactivo </span>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        </div>

                    </div>

                        <div class="mt-3">
                            <span class="float-end">Página {{paginacion.current_page}} de {{paginacion.last_page}}</span>
                            Registros por Página {{paginacion.per_page}}
                        </div>

                        <div class="btn-toolbar mt-4" role="toolbar" aria-label="Toolbar with button groups" v-show="paginacion.last_page>1">

                            <div class="btn-group mr-2" role="group" aria-label="First group">
                                <button type="button" class="btn btn-outline-secondary btn-sm"
                                    v-on:click="page=1; listarpersonal()">Primera Página</button>
                            </div>
                            <div class="btn-group mr-2" role="group" aria-label="Second group">
                                <button v-for="(n, index ) in paginacion.last_page" :key="index" type="button" class="btn btn-sm btn-outline-secondary" :class="{ 'btn-outline-secondary': n == page, 'btn-secondary': n == page }" v-on:click="page=n; listarpersonal()">{{n}}</button>
                            </div>
                            <div class="btn-group mr-3" role="group" aria-label="Third group">
                                <button type="button" class="btn btn-outline-secondary btn-sm" v-on:click="page=paginacion.last_page; listarpersonal()">Última Página</button>
                            </div>
                        </div>
            </div>
        </div>
</div>
    
</template>

<script>
    import axios from 'axios'
    import router from '../../router'


    export default {
        name: "personal",
        data() {
            return {
                cargando: true,
                personas: [],
                paginacion: [],
                criteriobusqueda: '',
                haybusqueda: false,
                page: 1,
                sedesautorizadas: [],
                filtrosedes: [],
                varmostrarmenu: false
            }
        },
        methods: {
            listarpersonal: function () {
                axios.get('/api/personal').then(response => {

                    this.cargando = false;
                    this.personas = response.data.data;
                    this.paginacion = response.data;

                });

                this.haybusqueda = false;
            },
            filtrarPersonal: function () {

                if(this.filtrosedes.length > 0){

                    axios.post('/api/personalfiltro').then(response => {
                        
                    this.cargando = false;
                    this.personas = response.data.data;
                    this.paginacion = response.data;
                    this.haybusqueda = false;

                     });

                }else{
                    this.listarpersonal();
                }
            },

            buscarpersona: function () {

                axios.post('/api/busquedapersona', { data: this.criteriobusqueda })
                    .then(response => { this.personas = response.data; });
                this.haybusqueda = true;

            },
            sedesPermitidas: function (){
            //sedes autorizadas  
            axios.get("/api/sedesautorizadas")
              .then(response => {
                this.sedesautorizadas = response.data;
              })
              .catch(function (error) {
                console.log("error" + error);
              });
            },

            mostrarMenu: function (){
                if(this.varmostrarmenu == false)
                {
                    this.varmostrarmenu = true
                }else{
                    this.varmostrarmenu = false
                }
            }
        },
        mounted() {
            this.listarpersonal();
            this.sedesPermitidas();
        }
    }
</script>